import { isGroupOrder } from "models/groupOrder/GroupOrder";
import { OrderUpsellGroupTitle, OrderUpsellResponse } from "models/order/OrderUpsell";
import { useEffect, useState } from "react";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { getStoredGroupOrderParticipantName } from "util/GroupOrderParticipantNameUtils";

const useOrderUpsell = () => {
  const [orderUpsellResponse, setOrderUpsellResponse] = useState<OrderUpsellResponse>();

  const { addToOrder, getOrderUpsell, order } = useOrder();
  const { user } = useUser();

  const smartUpsellItems = orderUpsellResponse?.groups.find(
    ({ title }) => title === OrderUpsellGroupTitle.Smart
  )?.items;

  const staticUpsellItems = orderUpsellResponse?.groups.find(
    ({ title }) => title === OrderUpsellGroupTitle.Static
  )?.items;

  const addUpsellToOrder = async (id: string, quantity: number) => {
    let recipientName: string | undefined;

    if (isGroupOrder(order)) {
      if (user) {
        recipientName = `${user.firstName} ${user.lastName}`;
      } else {
        recipientName = getStoredGroupOrderParticipantName();
      }
    }
    await addToOrder(id, quantity, undefined, undefined, recipientName, true);
  };

  useEffect(() => {
    (async () => {
      if (!order?.items.length) return;

      try {
        const response = await getOrderUpsell();
        setOrderUpsellResponse(response);
      } catch {
        setOrderUpsellResponse(undefined);
      }
    })();

    // order?.items must be in the deps array even though it is unused in the effect
    // so that we reload upsells when the order.items change
  }, [order?.items, getOrderUpsell]);

  return {
    addUpsellToOrder,
    order,
    smartUpsellItems,
    staticUpsellItems,
  };
};

export default useOrderUpsell;
