import { OrderError } from "errors/Errors";
import useAnalytics from "hooks/useAnalytics";
import { isGroupOrder } from "models/groupOrder";
import { OrderType } from "models/order/OrderType";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { MainPagePath } from "ui/navigation/Pages";
import { Formatter } from "util/Formatter";

const useOrderNavViewModel = () => {
  const { addErrorAlert } = useAlert();
  const { cancelGroupOrder, cancelOrder, order } = useOrder();
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const [shouldShowPopover, setShouldShowPopover] = useState(false);
  const { isGroupOrderHost } = useUser();
  const isCurrentUserAGroupOrderHost = isGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);

  const closePopover = () => setShouldShowPopover(false);

  const startOverLabel = (() => {
    if (isGroupOrder(order)) {
      return isGroupOrderHost(order.groupOrderDetails) ? "Cancel Group Order" : "Leave Group";
    }

    return "Start Over";
  })();

  const handleStartOver = async () => {
    closePopover();

    try {
      analytics.linkClick(startOverLabel, "Global Header");
      if (order) {
        analytics.cancelOrder(order.id);
      }

      const orderType = order?.orderType;

      if (isCurrentUserAGroupOrderHost) {
        await cancelGroupOrder();
      } else {
        await cancelOrder();
      }

      navigate({
        pathname: MainPagePath.locations,
        search: `method=${orderType === "delivery" ? "delivery" : "pickup"}`,
      });
    } catch (e) {
      addErrorAlert(OrderError.CancelOrder, e);
    }
  };

  const handleTogglePopover = () => {
    setShouldShowPopover((val) => !val);
  };

  const address = order?.orderType === OrderType.delivery ? order?.deliveryInfo?.address : order?.store.address;

  const cityStateZip = address
    ? `${address.city}${address.state ? `, ${address.state}` : ""} ${address.zipcode}`
    : null;

  const streetAddress = address
    ? `${address.addressLineOne}${address.addressLineTwo ? ` ${address.addressLineTwo}` : ""}`
    : null;

  const crossStreet = address?.crossStreet;
  const storeInfoUrl = Formatter.formatStoreInfoUrl(address, order?.store.name);
  const storePhone = Formatter.formatPhone(order?.store.phone);

  const shouldShowChangeLocationText = !isGroupOrder(order);

  return {
    cityStateZip,
    crossStreet,
    handleClosePopover: closePopover,
    handleStartOver,
    handleTogglePopover,
    orderType: order?.orderType,
    shouldShowChangeLocationText,
    shouldShowPopover,
    startOverLabel,
    storeInfoUrl,
    storePhone,
    streetAddress,
  };
};

export default useOrderNavViewModel;
