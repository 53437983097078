import { LoadingButton } from "@mui/lab";
import { Box, Button, Drawer, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import EmptyBagIcon from "assets/empty-bag.svg";
import { observer } from "mobx-react-lite";
import StickyAlertsView from "ui/app/StickyAlertsView";
import AddParticipantsModal from "ui/components/AddParticipantsModal/AddParticipantsModal";
import styles from "ui/components/Cart/Cart.styles";
import useViewModel from "ui/components/Cart/CartViewModel";
import LockGroupOrderModal from "ui/components/Cart/LockGroupOrderModal";
import CartItemList from "ui/components/CartItemList";
import CartUpsell from "ui/components/CartUpsell";
import Icon from "ui/components/Icon/Icon";
import OrderSummary from "ui/components/OrderSummary";
import StartGroupOrderModal from "ui/components/StartGroupOrderModal";

type CartProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Cart = observer(({ isOpen, onClose }: CartProps) => {
  const {
    addUpsellToOrder,
    cartBagTitle,
    cartItems,
    checkoutBtnLabel,
    handleAddFoodClick,
    handleCartItemQuantityChange,
    handleCartItemRemove,
    handleCheckoutClick,
    hideAddParticipantsModal,
    hideLockGroupOrderModal,
    hideStartGroupOrderModal,
    isCartEmpty,
    ledgerItems,
    shouldGroupCartItemsByRecipient,
    shouldShowAddParticipants,
    shouldShowAddParticipantsModal,
    shouldShowCheckoutLoading,
    shouldShowGroupOrderButton,
    shouldShowLockGroupOrderModal,
    shouldShowStartGroupOrderModal,
    showAddParticipantsModal,
    showStartGroupOrderModal,
    smartUpsellItems,
    staticUpsellItems,
    total,
  } = useViewModel({ isOpen, onClose });

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Drawer anchor="right" onClose={onClose} open={isOpen} sx={styles.drawer}>
        <Box sx={styles.header}>
          <Typography sx={styles.cartDrawerTitle} variant="h4">
            {cartBagTitle}
          </Typography>
          <IconButton aria-label="close the cart" onClick={onClose} sx={styles.closeIcon}>
            <Icon icon="closeDark" size="medium" />
          </IconButton>
        </Box>
        {!mdUp && <StickyAlertsView />}
        {shouldShowAddParticipants && <AddParticipantsButton onClick={showAddParticipantsModal} />}
        {isCartEmpty ? (
          <Box sx={styles.empty}>
            <img src={EmptyBagIcon} alt="An empty bag for a Jimmy John's order" />
            <Box>
              <Typography variant="h4">Your bag is empty</Typography>
              <Typography sx={styles.emptyBodyText} variant="body1">
                We know you&rsquo;re hungry, so add some food to your order, and we&rsquo;ll get it to you freaky fast.
              </Typography>
            </Box>
            <Button onClick={handleAddFoodClick} role="link">
              Back to Menu
            </Button>
            {shouldShowGroupOrderButton && (
              <Box sx={{ paddingTop: "1.5rem" }}>
                <Typography>It’s more fun with friends!</Typography>
                <GroupOrderButton onClick={showStartGroupOrderModal} />
              </Box>
            )}
          </Box>
        ) : (
          <>
            {shouldShowGroupOrderButton && (
              <Box sx={styles.groupOrderButtonWrapper}>
                <GroupOrderButton onClick={showStartGroupOrderModal} />
              </Box>
            )}
            <Box sx={styles.cartContent}>
              <Box>
                {cartItems && (
                  <CartItemList
                    cartItems={cartItems}
                    CartItemViewProps={{
                      modifiable: true,
                      onRemove: handleCartItemRemove,
                      onQuantityChange: handleCartItemQuantityChange,
                    }}
                    groupByRecipient={shouldGroupCartItemsByRecipient}
                  />
                )}
                <CartUpsell
                  addUpsellToOrder={addUpsellToOrder}
                  smartUpsellItems={smartUpsellItems}
                  staticUpsellItems={staticUpsellItems}
                />
              </Box>
              <OrderSummary ledgerItems={ledgerItems} total={total} />
            </Box>
            <Box sx={styles.ctas}>
              <Button variant="outlined" onClick={handleAddFoodClick} sx={styles.cta} role="link">
                Add Food
              </Button>
              <LoadingButton loading={shouldShowCheckoutLoading} onClick={handleCheckoutClick} sx={styles.cta}>
                {checkoutBtnLabel}
              </LoadingButton>
            </Box>
          </>
        )}
      </Drawer>
      {shouldShowStartGroupOrderModal && (
        <StartGroupOrderModal onClose={hideStartGroupOrderModal} open={shouldShowStartGroupOrderModal} />
      )}
      {shouldShowAddParticipantsModal && (
        <AddParticipantsModal onClose={hideAddParticipantsModal} open={shouldShowAddParticipantsModal} />
      )}
      {shouldShowLockGroupOrderModal && (
        <LockGroupOrderModal onClose={hideLockGroupOrderModal} open={shouldShowLockGroupOrderModal} />
      )}
    </>
  );
});

const GroupOrderButton = ({ onClick }: { onClick: () => void }) => (
  <Stack sx={styles.groupOrderButton}>
    <Icon icon="groupOrder" />
    <Button onClick={onClick} variant="text">
      Make this a group order
    </Button>
  </Stack>
);

const AddParticipantsButton = ({ onClick }: { onClick: () => void }) => (
  <Stack sx={styles.addParticipantsButton}>
    <Button onClick={onClick} variant="text">
      Add More People
    </Button>
    <Icon icon="groupOrder" />
  </Stack>
);

export default Cart;
