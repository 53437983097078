import { OrderDataTransformable } from "dataTransformers/OrderDataTransformable";
import { LocalErrorCodes } from "errors/LocalErrorCodes";
import { isOctocartError } from "errors/OctocartError";
import { action, autorun, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { AcceptGroupOrderInviteResponse, isGroupOrder } from "models/groupOrder";
import { AddItem } from "models/order/AddItem";
import { CartItem } from "models/order/CartItem";
import { DeliveryInfo } from "models/order/DeliveryInfo";
import { Order } from "models/order/Order";
import { OrderType } from "models/order/OrderType";
import Tip from "models/order/Tip";
import UpdateAndSubmitPayload from "models/order/UpdateAndSubmitOrderPayload";
import { OrderConfirmation } from "models/OrderConfirmation";
import { Store } from "models/store/Store";
import CreateGroupOrderPayload from "networking/requestPayloads/CreateGroupOrderPayload";
import SendGroupOrderInvitesPayload from "networking/requestPayloads/SendGroupOrderInvitesPayload";
import UpdateGroupOrderPayload from "networking/requestPayloads/UpdateGroupOrderPayload";
import { BaseStore } from "stores/BaseStore";
import { WritableLoadableObservableState } from "stores/LoadableObservableState";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import { OrderStorable } from "stores/order/OrderStorable";
import { ProductModifier } from "ui/screens/Product/ProductModifiers/ProductModifierManager";
import { updateOptimizelyUserAttributes } from "util/Optimizely";
import parseStoreNumber from "util/parseStoreNumber";

class OrderStore extends BaseStore implements OrderStorable {
  @observable orderConfirmation?: OrderConfirmation;

  readonly orderState: WritableLoadableObservableState<Order> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  private readonly orderDataTransformable: OrderDataTransformable;

  constructor(orderDataTransformable: OrderDataTransformable) {
    super();
    makeObservable(this);
    makeAutoObservable(this.orderState);
    this.orderDataTransformable = orderDataTransformable;

    // keep Optimizely user handoff_type attribute in sync with order state
    autorun(() => updateOptimizelyUserAttributes({ handoff_type: this.order?.orderType }));

    // keep Optimizely user store_number attribute in sync with order state
    autorun(() => {
      if (!this.order?.store) {
        updateOptimizelyUserAttributes({ store_number: undefined });
        return;
      }

      const store_number = parseStoreNumber(this.order.store.name) ?? this.order.store.name;
      updateOptimizelyUserAttributes({ store_number });
    });

    // reset Optimizely user payment_method attribute when order state is reset (submission, cancel, expires, etc)
    autorun(() => {
      if (!this.order) {
        updateOptimizelyUserAttributes({ payment_method: undefined });
      }
    });
  }

  @computed get order() {
    return this.orderState.object;
  }

  @computed get isLoadingOrder() {
    return this.orderState.object === undefined && this.orderState.status !== LoadableObservableStatus.Error;
  }

  @computed get orderItemsCount() {
    return this.orderState.object?.items.reduce((count, item) => {
      return count + item.quantity;
    }, 0);
  }

  @computed get orderLedgerItems() {
    return this.orderState.object?.totals.filter(({ id }) => id !== "total") ?? [];
  }

  @computed get orderTotal() {
    return this.orderState.object?.totals.find(({ id }) => id === "total")?.value ?? "";
  }

  applyTip = (tip: Tip) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .applyTip(tip)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  addToOrder = (
    itemId: string,
    quantity = 1,
    modifiers: ProductModifier[] = [],
    specialInstructions?: string,
    recipient?: string,
    isUpsell?: boolean
  ) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .addToOrder(itemId, quantity, modifiers, specialInstructions, recipient, isUpsell)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  addMultipleItemsToOrder(items: AddItem[]) {
    this.startOrderModification();
    return this.orderDataTransformable
      .addMultipleItemsToOrder(items)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  }

  repeatOrder = async (orderId: string) => {
    try {
      if (this.order) {
        await this.cancelOrder();
      }
      const response = await this.orderDataTransformable.repeatOrder(orderId);

      this.handleOrderResponse(response);

      return Promise.resolve();
    } catch (error) {
      return this.handleError(error);
    }
  };

  applyCoupon(coupon: string) {
    this.startOrderModification();
    return this.orderDataTransformable
      .applyCoupon(coupon)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  }

  /**
   * Cancels the order. If the response from the server is an error but the
   * error is that the order is not found, it is silenced.
   * @param [options] object containing `skipUpdate` boolean,
   * where `skipUpdate` means we want to clear the order state.
   */
  cancelOrder = async (options?: { skipUpdate?: boolean }) => {
    this.startOrderModification();

    try {
      await this.orderDataTransformable.cancelOrder();

      if (!options?.skipUpdate) {
        this.clearOrderState();
      }
    } catch (error) {
      if (isOctocartError(error) && error.code === LocalErrorCodes.OrderNotFound) {
        this.clearOrderState();
        return;
      }

      this.handleError(error);
    }
  };

  createOrder = async (store: Store) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .createOrder(store)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  createDeliveryOrder = async (store: Store, deliveryInfo: DeliveryInfo, specialInstructions?: string) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .createOrder(store)
      .then(() => this.updateOrderType(OrderType.delivery, deliveryInfo, specialInstructions))
      .catch((error) => this.handleError(error));
  };

  deleteFromOrder = async (orderItem: CartItem) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .deleteFromOrder(orderItem)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  editOrderItem(
    orderItem: CartItem,
    quantity: number,
    modifiers: ProductModifier[],
    specialInstructions?: string,
    recipient?: string
  ) {
    this.startOrderModification();
    return this.orderDataTransformable
      .editOrderItem(orderItem, quantity, modifiers, specialInstructions, recipient)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  }

  editOrderItemQuantity = async (orderItem: CartItem, quantity: number) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .editOrderItemQuantity(orderItem, quantity)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  getOrder() {
    this.startOrderModification();
    return this.orderDataTransformable
      .getOrder()
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  }

  getOrderUpsell = async () => {
    try {
      const response = await this.orderDataTransformable.getOrderUpsell();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  addFaveToOrder = async (faveId: string) => {
    try {
      const faveOrderResponse = await this.orderDataTransformable.addFaveToOrder(faveId);
      this.analytics.userFavs("Add Fav To Order");
      this.handleOrderResponse(faveOrderResponse.order);
      return faveOrderResponse;
    } catch (error) {
      return this.handleError(error);
    }
  };

  redeemReward = async (reference: string) => {
    try {
      this.startOrderModification();

      // only 1 reward can be applied to an order; remove currently applied reward, if any.
      if (this.order?.appliedRewards?.[0]?.rewardId) {
        await this.removeReward(this.order.appliedRewards[0].rewardId);
      }

      const response = await this.orderDataTransformable.redeemReward(reference);
      return this.handleOrderResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  };

  removeCoupon() {
    this.startOrderModification();
    return this.orderDataTransformable
      .removeCoupon()
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  }

  removeReward = (rewardId: number) => {
    const rewardString = rewardId.toString();
    this.startOrderModification();
    return this.orderDataTransformable
      .removeReward(rewardString)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  /**
   * Validates the order
   */
  validateOrder = () => {
    this.startOrderModification();
    return this.orderDataTransformable
      .validateOrder()
      .then((response) => {
        this.handleOrderResponse(response);
        return Promise.resolve(response);
      })
      .catch((error) => this.handleError(error));
  };

  /**
   * Submits the order
   */
  submitOrder() {
    this.startOrderModification();
    return this.orderDataTransformable
      .submitOrder()
      .then((response) => this.handleOrderConfirmation(response))
      .catch((error) => this.handleError(error));
  }

  /**
   * Submits the order
   */
  updateAndSubmitOrder = (payload: UpdateAndSubmitPayload) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .updateAndSubmitOrder(payload)
      .then((response) => {
        this.handleOrderConfirmation(response);
        return response;
      })
      .catch((error) => this.handleError(error));
  };

  /**
   * checks in the user when they have arrived for curbside pickup
   */
  checkinOrder = async (orderId: string) => {
    try {
      const response = await this.orderDataTransformable
        .checkinOrder(orderId)
        .then(() => this.analytics.orderCheckIn("Check InOrder"));
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  /**
   * Sets the order method for the order
   */
  @action updateOrderType = (orderType: OrderType, deliveryInfo?: DeliveryInfo, specialInstructions?: string) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .setOrderType(orderType, deliveryInfo, specialInstructions)
      .then((response) => this.handleOrderResponse(response))
      .catch((error) => this.handleError(error));
  };

  private clearOrderState = () => {
    this.handleOrderResponse(undefined);
  };

  /**
   * Updates the order object. All responses from the OrderDataTransformer should route through this method in order
   * to update the store.
   */
  @action private handleOrderResponse(response: Order | undefined) {
    this.orderState.object = response;
    this.orderState.error = undefined;
    this.orderState.status = LoadableObservableStatus.Idle;
  }

  @action handleError(error: unknown) {
    this.orderState.status = LoadableObservableStatus.Error;

    if (isOctocartError(error)) {
      this.orderState.error = error;

      if (error.code === LocalErrorCodes.OrderNotFound) {
        this.orderState.object = undefined;
      }
    }

    return Promise.reject(error);
  }

  /**
   * Updates the OrderConfirmation object. All responses from the OrderDataTransformer should route through this method
   * in order to update the store.
   */
  @action private handleOrderConfirmation(response: OrderConfirmation | undefined) {
    this.clearOrderState();
    this.orderConfirmation = response;
  }

  @action private startOrderModification() {
    this.orderState.status = LoadableObservableStatus.Loading;
  }

  updateTimeWanted = async (timeWanted: string) => {
    this.startOrderModification();
    return this.orderDataTransformable
      .setOrderTimeWanted(timeWanted)
      .then(() => this.validateOrder())
      .catch((error) => this.handleError(error));
  };

  /******************
   * Group Ordering *
   ******************/

  acceptGroupOrderInvite = async (
    groupOrderId: string,
    participantId: string,
    participantName: string
  ): Promise<AcceptGroupOrderInviteResponse> => {
    this.startOrderModification();
    try {
      const response = await this.orderDataTransformable.acceptGroupOrderInvite(
        groupOrderId,
        participantId,
        participantName
      );
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  cancelGroupOrder = async () => {
    this.startOrderModification();

    try {
      if (!isGroupOrder(this.order)) throw new Error("Order is not a group order");

      await this.orderDataTransformable.cancelGroupOrder(this.order.groupOrderDetails.id);

      this.clearOrderState();
    } catch (error) {
      if (isOctocartError(error) && error.code === LocalErrorCodes.OrderNotFound) {
        this.clearOrderState();
        return;
      }

      this.handleError(error);
    }
  };

  createGroupOrder = async (payload: CreateGroupOrderPayload) => {
    this.startOrderModification();

    try {
      await this.orderDataTransformable.createGroupOrder(payload);
      await this.getOrder();
    } catch (error) {
      this.handleError(error);
    }
  };

  getCurrentGroupOrderParticipants = async () => {
    try {
      if (!isGroupOrder(this.order)) {
        throw new Error("Current order is not a group order");
      }

      return await this.orderDataTransformable.getCurrentGroupOrderParticipants(this.order.groupOrderDetails.id);
    } catch (error) {
      return this.handleError(error);
    }
  };

  getGroupOrder = async (groupOrderId: string) => {
    try {
      return await this.orderDataTransformable.getGroupOrder(groupOrderId);
    } catch (error) {
      return this.handleError(error);
    }
  };

  joinGroupOrder = async (groupOrderId: string, orderId?: string) => {
    this.startOrderModification();
    try {
      const response = await this.orderDataTransformable.joinGroupOrder(groupOrderId, orderId);
      return this.handleOrderResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  };

  lockGroupOrder = async () => {
    try {
      if (!isGroupOrder(this.order)) {
        throw new Error("Current order is not a group order");
      }

      await this.updateGroupOrder(this.order.groupOrderDetails.id, { isLocked: true });
    } catch (error) {
      // Nothing to do with this error, "behind the scenes" app logic and not user-facing
    }
  };

  sendGroupOrderInvites = async (groupOrderId: string, payload: SendGroupOrderInvitesPayload) => {
    try {
      return await this.orderDataTransformable.sendGroupOrderInvites(groupOrderId, payload);
    } catch (error) {
      return this.handleError(error);
    }
  };

  submitGroupOrder = async (groupOrderId: string) => {
    try {
      await this.orderDataTransformable.submitGroupOrder(groupOrderId);
    } catch (error) {
      return this.handleError(error);
    }
  };

  unlockGroupOrder = async () => {
    try {
      if (!isGroupOrder(this.order)) {
        throw new Error("Current order is not a group order");
      }

      await this.updateGroupOrder(this.order.groupOrderDetails.id, { isLocked: false });
    } catch (error) {
      // Nothing to do with this error, "behind the scenes" app logic and not user-facing
    }
  };

  private async updateGroupOrder(groupOrderId: string, payload: UpdateGroupOrderPayload) {
    this.startOrderModification();

    try {
      await this.orderDataTransformable.updateGroupOrder(groupOrderId, payload);
      await this.getOrder();
    } catch (error) {
      this.handleError(error);
    }
  }
}

export default OrderStore;
