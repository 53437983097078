import AchievementBadge from "models/rewards/AchievementBadge";
import LocalStorage from "util/LocalStorage";

/**
 * Helpers for managing local "seen" state of achievement badges;
 * protects against network issues causing infinite modal display loops
 **/

const locallySeenAvailableBadgeStorage = new LocalStorage<string[]>("seen_available_badges");
const locallySeenAchievedBadgeStorage = new LocalStorage<string[]>("seen_achieved_badges");

export const setBadgesAsLocallySeen = (badges: AchievementBadge[]) => {
  // Filter and set "AVAILABLE" badges as locally seen
  const locallySeenAvailableBadges = locallySeenAvailableBadgeStorage.get() ?? [];
  const newSeenAvailableBadges = badges
    .filter(({ status }) => status === "AVAILABLE")
    .filter(({ id }) => !locallySeenAvailableBadges.includes(id));
  const newSeenAvailableBadgeIDs = newSeenAvailableBadges.map(({ id }) => id);
  const seenAvailableBadgeIds = locallySeenAvailableBadges.concat(newSeenAvailableBadgeIDs);

  locallySeenAvailableBadgeStorage.set(seenAvailableBadgeIds);

  // Filter and set "ACHIEVED" badges as locally seen
  const locallySeenAchievedBadges = locallySeenAchievedBadgeStorage.get() ?? [];
  const newSeenAchievedBadges = badges
    .filter(({ status }) => status === "ACHIEVED")
    .filter(({ id }) => !locallySeenAchievedBadges.includes(id));
  const newSeenAchievedBadgeIDs = newSeenAchievedBadges.map(({ id }) => id);
  const seenAchievedBadgeIds = locallySeenAchievedBadges.concat(newSeenAchievedBadgeIDs);

  locallySeenAchievedBadgeStorage.set(seenAchievedBadgeIds);
};

export const getLocallySeenAvailableBadges = () => locallySeenAvailableBadgeStorage.get() ?? [];
export const getLocallySeenAchievedBadges = () => locallySeenAchievedBadgeStorage.get() ?? [];
