import { ProductDataTransformable } from "dataTransformers/ProductDataTransformable";
import { isOctocartError, OctocartError } from "errors/OctocartError";
import _ from "lodash";
import { action, computed, makeAutoObservable, makeObservable, observable, reaction } from "mobx";
import { DynamicData } from "models/DynamicData";
import { Filter, FilterOption } from "models/Filter";
import MetadataKey from "models/MetadataKey";
import { ModifierCategory } from "models/ModifierCategory";
import { ProductItem } from "models/ProductItem";
import { Store } from "models/store/Store";
import { BaseStore } from "stores/BaseStore";
import { WritableLoadableObservableState } from "stores/LoadableObservableState";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import { ProductStorable } from "stores/menu/ProductStorable";
import { ProductModifier } from "ui/screens/Product/ProductModifiers/ProductModifierManager";

class ProductStore extends BaseStore implements ProductStorable {
  @observable currentStore?: Store;
  @observable filters?: Filter[];

  readonly categoriesState: WritableLoadableObservableState<FilterOption[]> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  readonly productState: WritableLoadableObservableState<ProductItem[]> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  private readonly dataTransformer: ProductDataTransformable;

  constructor(productDataTransformable: ProductDataTransformable) {
    super();
    makeObservable(this);
    makeAutoObservable(this.categoriesState);
    makeAutoObservable(this.productState);
    this.dataTransformer = productDataTransformable;

    // when the current store changes, update the menu/products with the menu/products for the new store
    reaction(
      () => this.currentStore,
      () => this.refreshProductsAndFiltersForStore()
    );
  }

  @computed get isLoadingProducts() {
    return (
      this.productState.status == LoadableObservableStatus.Loading ||
      (this.productState.object === undefined && this.productState.status !== LoadableObservableStatus.Error)
    );
  }

  @computed get isLoadingCategories() {
    return (
      this.categoriesState.status == LoadableObservableStatus.Loading ||
      (this.categoriesState.object === undefined && this.categoriesState.status !== LoadableObservableStatus.Error)
    );
  }

  @computed get categories(): FilterOption[] | undefined {
    return this.categoriesState.object;
  }

  @computed get products(): ProductItem[] | undefined {
    return this.productState.object;
  }

  @computed get currentStoreId(): string {
    return this.currentStore?.id ?? Env.NationalStoreId ?? "";
  }

  @computed get isNationalMenu(): boolean {
    return !!Env.NationalStoreId && this.currentStoreId === Env.NationalStoreId;
  }

  @computed get sidesAndDrinks() {
    if (!this.products) return;

    const sidesAndDrinks = new Map<string, ProductItem[]>();

    for (const product of this.products) {
      const dropdownGroupName = product.dynamicData.metaData.find(
        ({ name }) => name === MetadataKey.ProductDropdownGroup
      )?.value;

      if (!dropdownGroupName) continue;

      const dropdownGroup = sidesAndDrinks.get(dropdownGroupName);

      if (dropdownGroup) {
        dropdownGroup.push(product);
        sidesAndDrinks.set(dropdownGroupName, dropdownGroup);
      } else {
        sidesAndDrinks.set(dropdownGroupName, [product]);
      }
    }

    return sidesAndDrinks;
  }

  @computed get cateringSidesAndDrinks() {
    if (!this.products) return;

    const cateringSidesAndDrinks = new Map<string, ProductItem[]>();

    for (const product of this.products) {
      let groupName = "";

      const productCategory = product.categories[0];

      const cateringProducts = productCategory.metadata?.find(
        ({ key, value }) => key === MetadataKey.MenuView && value === "catering"
      );

      if (cateringProducts && ProductStore.isSideOrDrink(product)) {
        groupName = productCategory.name;
      }

      if (!groupName) continue;

      const dropdownGroup = cateringSidesAndDrinks.get(groupName);

      if (dropdownGroup) {
        dropdownGroup.push(product);
        cateringSidesAndDrinks.set(groupName, dropdownGroup);
      } else {
        cateringSidesAndDrinks.set(groupName, [product]);
      }
    }

    return cateringSidesAndDrinks;
  }

  fetchDynamicDataForProduct(product: ProductItem, modifiers?: ProductModifier[]): Promise<DynamicData> {
    return this.dataTransformer.fetchDynamicData(product, this.currentStoreId, modifiers);
  }

  getModifierCategoriesForProduct(productId: string): Promise<ModifierCategory[]> {
    return this.dataTransformer.getModifiers(productId, this.currentStoreId);
  }

  getProductsForCategory = (categoryId: string): Promise<ProductItem[]> => {
    return this.dataTransformer.getProducts(this.currentStoreId, categoryId).then((products) => products.products);
  };

  @action async getProducts() {
    try {
      this.productState.status = LoadableObservableStatus.Loading;
      const response = await this.dataTransformer.getProducts(this.currentStoreId);
      this.setProducts(response.products);
    } catch (e) {
      if (isOctocartError(e)) {
        this.setProducts(undefined, e);
      }

      throw e;
    }
  }

  @action refreshProductsAndFiltersForStore() {
    return Promise.allSettled([this.getProducts(), this.getCategories()]).then((responses) => {
      const rejection = _.head(responses.filter((p) => p.status === "rejected"));

      if (rejection) {
        return Promise.reject((rejection as PromiseRejectedResult).reason);
      }

      return Promise.resolve();
    });
  }

  @action clearStore = (): void => {
    this.productState.object = undefined;
    this.setCategories(undefined);
    this.filters = undefined;
    this.currentStore = undefined;
  };

  @action private setProducts(products: ProductItem[] | undefined, error?: OctocartError): void {
    if (error) {
      this.productState.object = undefined;
      this.productState.status = LoadableObservableStatus.Error;
    } else {
      this.productState.object = products;
      this.productState.status = LoadableObservableStatus.Idle;
    }
  }

  @action private setFilters(filters: Filter[] | undefined): void {
    this.filters = filters;
  }

  @action private setCategories(categories?: FilterOption[] | undefined, error?: OctocartError): void {
    if (error) {
      this.categoriesState.object = undefined;
      this.categoriesState.status = LoadableObservableStatus.Error;
    } else {
      this.categoriesState.object = categories;
      this.categoriesState.status = LoadableObservableStatus.Idle;
    }
  }

  @action getCategories(): Promise<void> {
    this.categoriesState.object = undefined;
    this.categoriesState.status = LoadableObservableStatus.Loading;

    return this.dataTransformer
      .getFilters(this.currentStoreId)
      .then((response) => {
        this.setFilters(response.filters);
        this.setCategories(response.categories);
        return Promise.resolve();
      })
      .catch((e) => {
        if (isOctocartError(e)) {
          this.setCategories(undefined, e);
        }

        throw e;
      });
  }

  static getCategoryBanner(category: FilterOption) {
    const text = category.metadata?.find((metadataObj) => {
      return metadataObj.key === MetadataKey.BannerText;
    })?.value;

    if (!text) return;

    let backgroundColor: string | undefined;

    const bgColorHex = category.metadata?.find((metadataObj) => {
      return metadataObj.key === MetadataKey.BannerBackgroundColor;
    })?.value;

    if (bgColorHex && /[0-9A-F]{6}/i.test(bgColorHex)) {
      backgroundColor = `#${bgColorHex}`;
    }

    return { backgroundColor, text };
  }

  static getProductBanner(product: ProductItem) {
    const metadata = product.dynamicData.metaData;
    const text = metadata?.find((metadataObj) => {
      return metadataObj.name === MetadataKey.BannerText;
    })?.value;

    if (!text) return;

    let backgroundColor: string | undefined;

    const bgColorHex = metadata?.find((metadataObj) => {
      return metadataObj.name === MetadataKey.BannerBackgroundColor;
    })?.value;

    if (bgColorHex && /[0-9A-F]{6}/i.test(bgColorHex)) {
      backgroundColor = `#${bgColorHex}`;
    }

    return { backgroundColor, text };
  }

  static isSideOrDrink(product: ProductItem) {
    return product.categories.some((category) =>
      category.metadata?.find(
        ({ key, value }) =>
          (key === MetadataKey.MenuCategoryType && value === "sides") ||
          (key === MetadataKey.MenuCategoryType && value === "drinks")
      )
    );
  }
}

export default ProductStore;
