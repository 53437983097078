import { head, isNumber } from "lodash";
import MetadataKey from "models/MetadataKey";
import { ModifierCategory } from "models/ModifierCategory";
import { BaseModifier, ModifierId } from "models/modifiers/BaseModifier";
import { Modifier } from "models/modifiers/Modifier";
import { ModifierOption, ModifierOptionId } from "models/modifiers/ModifierOption";
import { ModifierType } from "models/modifiers/ModifierType";
import { isMultiSelectModifier, MultiSelectModifier } from "models/modifiers/MultiSelectModifier";
import { isOptionModifier, OptionModifier } from "models/modifiers/OptionModifier";
import { cloneObj, first } from "util/JavascriptUtility";

export type ProductModifier = OptionModifier & {
  /** If the modifier is a child of another modifier option, this is the parent option's ID */
  parentOptionId?: ModifierOptionId;
  /** True if the option can be selected, false if it cannot/is not visible */
  enabled: boolean;
  /** Currently selected options */
  selectedOptions: ModifierOptionId[];
};

export class ProductModifierManager {
  /**
   * This method is to determine if two child modifiers can be considered "equivalent", meaning they are the same
   * modifier under different parents. They may have different modifier IDs, but they would equate to the same
   * modification. For example, sandwichA and sandwichB both can add ham. "ham" may have modifier ID abc as a child
   * modifier of sandwichA and "123" as a child modifier for sandwichB, but they're equivalent modifiers for each
   * sandwich
   */
  public static readonly modifiersAreEquivalent = (modifier1: BaseModifier, modifier2: BaseModifier): boolean => {
    return modifier1.id === modifier2.id || (modifier1.text == modifier2.text && modifier1.type == modifier2.type);
  };

  /** Extracts modifiers and their children from ModifierCategory */
  public static readonly flattenModifierCategories = (modifierCategories: ModifierCategory[]): Modifier[] => {
    return modifierCategories.map((m) => m.modifiers).reduce((a, b) => a.concat(b), []);
  };

  /** Creates the flat array of modifiers with default options */
  public static readonly setDefaultModifierOptions = (modifiers: Modifier[]): ProductModifier[] => {
    return ProductModifierManager.setSelections(modifiers, true);
  };

  /** Applies an array of modifier option Ids to the productModifiers. */
  public static readonly applyModifierOptions = (
    modifiers: Modifier[],
    options: ModifierOptionId[]
  ): ProductModifier[] => {
    return ProductModifierManager.setSelectionsForCart(modifiers, options, true);
  };

  /** Returns all of the enabled and selected option Ids. */
  public static readonly flattenSelectedOptionIds = (productModifiers: ProductModifier[]): ModifierOptionId[] => {
    return productModifiers
      .filter((pm) => pm.enabled)
      .map((pm) => pm.selectedOptions)
      .reduce((a, b) => a.concat(b), []);
  };

  /** Returns only the root (or top level) modifiers. */
  public static readonly getRootModifiers = (productModifiers: ProductModifier[]): ProductModifier[] => {
    return productModifiers.filter((m) => !m.parentOptionId);
  };

  /**
   * Returns true if all required enabled modifiers have appropriate selections
   */
  public static readonly modifiersAreValid = (productModifiers: ProductModifier[]): boolean => {
    const requiredModifiers = productModifiers.filter((m) => m.required && m.enabled);
    const selectedModifiers = requiredModifiers.filter((m) => m.selectedOptions.length > 0);
    // Check that all modifiers with a min/max are valid
    const invalidStepperModifiers = productModifiers.filter((m) => {
      if (!isMultiSelectModifier(m)) return false;
      // If its parent is not selected, it's not available
      if (!m.enabled) return false;
      // If it's not required and has 0 selections, it can be skipped
      if (!m.required && m.selectedOptions.length === 0) return false;
      const multiSelectModifier = m as MultiSelectModifier;
      if (isNumber(multiSelectModifier.minSelections) && m.selectedOptions.length < multiSelectModifier.minSelections)
        return true;
      return (
        isNumber(multiSelectModifier.maxSelections) && m.selectedOptions.length > multiSelectModifier.maxSelections
      );
    });

    return requiredModifiers.length === selectedModifiers.length && invalidStepperModifiers.length == 0;
  };

  /** Returns all child modifiers of an option (including their sub children). */
  public static readonly getAllChildModifiers = (
    productModifiers: ProductModifier[],
    optionId: ModifierOptionId
  ): ProductModifier[] => {
    const childModifiers: ProductModifier[] = [];
    const clonedModifiers = cloneObj(productModifiers);

    clonedModifiers.forEach((m) => {
      if (m.parentOptionId !== optionId) return;
      childModifiers.push(m);
      if (m.options) {
        m.options.forEach((o) => {
          if (!m.selectedOptions.includes(o.id) || !o.modifiers) return;
          childModifiers.push(...ProductModifierManager.getAllChildModifiers(clonedModifiers, o.id));
        });
      }
    });

    return childModifiers;
  };

  /** Updates the array for the new options, including enabling all child modifiers and returns the new array. */
  public static readonly onChange = (
    productModifiers: ProductModifier[],
    changingModifierId: ModifierId,
    selectedOptions: ModifierOptionId[]
  ): ProductModifier[] => {
    let clonedModifiers = cloneObj(productModifiers);

    // using the modifier id, retrieve the modifier object from the full modifiers array
    const changingModifier = clonedModifiers.find((m) => m.id === changingModifierId);

    if (!changingModifier) return clonedModifiers;

    // compare the `selectedOptions` (desired state) with the `changingModifier.selectedOptions` (current state)
    // to determine which options need to be selected vs unselected
    const removedIds = changingModifier.selectedOptions.filter((o) => !selectedOptions.includes(o));

    const addedIds = selectedOptions.filter((o) => !changingModifier.selectedOptions.includes(o));

    const changedIds = [...removedIds, ...addedIds];

    // no changes requested, returns the modifiers as-is
    if (!changedIds) return clonedModifiers;

    // for singleselect options (radio), changing your choice looks like -1 / +1
    if (changingModifier.type == ModifierType.SingleSelect && removedIds.length == 1 && addedIds.length == 1) {
      const oldModifierOptionId = removedIds[0];
      const newModifierOptionId = addedIds[0];

      // attempt to apply equivalent child modifiers to the new modifiers
      // ex: my sandwich customizations should still apply to french bread vs wheat
      clonedModifiers = ProductModifierManager.applyMatchingChildModifiers(
        clonedModifiers,
        changingModifier.id,
        newModifierOptionId,
        oldModifierOptionId
      );
    }

    // apply new selectionOptions to the modifier
    changingModifier.selectedOptions = selectedOptions;

    // reconcile any visibility changes that need to happen when we select an option
    // ex: selecting "GIANT" size removes bread option section because bread opts is a child of "REGULAR" size
    clonedModifiers.forEach((pm) => {
      // this doesn't apply if the given modifier in this loop is a root modifier
      if (!pm.parentOptionId) return;

      // this also doesn't apply if the given modifier's parent isn't named in changedIds
      if (!changedIds.includes(pm.parentOptionId)) return;

      // figure out if the given modifier's parent is enabled (visible)
      const isParentEnabled =
        clonedModifiers.find(({ options }) => options.some(({ id }) => id === pm.parentOptionId))?.enabled ?? false;

      // set this modifier to `enabled` (visible) based on whether or not it's parent is visible
      // AND the parent is selected
      pm.enabled =
        isParentEnabled && ProductModifierManager.flattenSelectedOptionIds(clonedModifiers).includes(pm.parentOptionId);
    });

    ProductModifierManager.enableChildModifiers(clonedModifiers, changedIds);
    return clonedModifiers;
  };

  /** Merges new modifications (newProductModifiers) into an existing tree, by applying the new options to the old
      options, while leaving older options that have not been modified */
  public static readonly mergeModifiers = (
    oldProductModifiers: ProductModifier[],
    newProductModifiers: ProductModifier[]
  ): ProductModifier[] => {
    const newTree: ProductModifier[] = [];
    const childModifierIds = newProductModifiers.map((m) => m.id);
    oldProductModifiers.forEach((modifier) => {
      if (!childModifierIds.includes(modifier.id)) {
        newTree.push(modifier);
        return;
      }
      const newModifier = first(newProductModifiers.filter((m) => m.id === modifier.id));
      if (!newModifier) {
        newTree.push(modifier);
        return;
      }
      newTree.push(newModifier);
    });
    return newTree;
  };

  /**
   * When a modifier is changed and there are equivalent children modifiers, choose those.
   * For example, toppings of a sandwich may be a child modifier of the bread type. If the customer chose no tomato
   * on one bread, we'd want to keep that same no tomato configuration on the other bread type, even if the IDs are
   * different
   */
  public static readonly applyMatchingChildModifiers = (
    productModifiers: ProductModifier[],
    changingModifierId: ModifierId,
    newOption: ModifierOptionId,
    removedOption: ModifierOptionId
  ): ProductModifier[] => {
    // if the modifiers include sandwich selection modifiers,
    // then do not transfer/apply child modifiers
    const isCateringBundle = productModifiers.some((m) => {
      const modifierGroupType = m.metadata?.find(({ key }) => key === MetadataKey.ModifierGroupType)?.value;
      return modifierGroupType === "sandwich-selection";
    });

    if (isCateringBundle) return productModifiers;

    const modifier = first(productModifiers.filter((m) => m.id === changingModifierId));
    if (!modifier) return productModifiers;

    const modifierOptionIds = modifier.options.map((o) => o.id);
    if (!(modifierOptionIds.includes(newOption) && modifierOptionIds.includes(removedOption))) {
      // Modifier does not include both options therefore we shouldn't apply children
      return productModifiers;
    }

    const oldChildrenModifiers = ProductModifierManager.getAllChildModifiers(productModifiers, removedOption);
    const newChildrenModifiers = ProductModifierManager.getAllChildModifiers(productModifiers, newOption);
    oldChildrenModifiers.forEach((oldModifier) => {
      const newModifier = head(
        newChildrenModifiers.filter((m) => ProductModifierManager.modifiersAreEquivalent(m, oldModifier))
      );

      if (!newModifier || !newModifier.options) {
        return;
      }

      const selectedModifierOptions = oldModifier.options.filter((o) => oldModifier.selectedOptions.includes(o.id));
      const unselectedModifierOptions = oldModifier.options.filter((o) => !oldModifier.selectedOptions.includes(o.id));

      newModifier.options.forEach((modifierOption) => {
        // Find the matching modifier option in the old options
        const oldMatchingModifierOption = head(oldModifier.options.filter((o) => o.text == modifierOption.text));
        // If there isn't an equivalent modifier option, skip it
        if (!oldMatchingModifierOption) {
          return;
        }
        if (
          selectedModifierOptions.includes(oldMatchingModifierOption) &&
          !newModifier.selectedOptions?.includes(modifierOption.id)
        ) {
          // if it's selected in the old one and not selected in the new one, select it in the new one
          let selectedOptions = newModifier.selectedOptions ?? [];
          if (
            newModifier.type == ModifierType.SingleSelect ||
            (newModifier.type == ModifierType.MultiSelect && (newModifier as MultiSelectModifier).maxSelections == 1)
          ) {
            // If it's a single select, select only that modifier
            selectedOptions = [modifierOption.id];
          } else {
            selectedOptions.push(modifierOption.id);
          }
          newModifier.selectedOptions = selectedOptions;
        } else if (
          unselectedModifierOptions.includes(oldMatchingModifierOption) &&
          newModifier.selectedOptions?.includes(modifierOption.id)
        ) {
          // if it's deselected in the old one and selected in the one, deselect it in the new one
          newModifier.selectedOptions = newModifier.selectedOptions?.filter((o) => o !== newModifier.id);
        }
      });
      const matchedModifier = productModifiers.filter((m) => m.id === newModifier.id)[0];
      const index = productModifiers.indexOf(matchedModifier);
      productModifiers[index].selectedOptions = newModifier.selectedOptions;
    });

    return productModifiers;
  };

  public static PreSelectedModifierTitles = ["Customize Sandwich"];

  private static readonly getPreSelectedOptions = (modifier: OptionModifier): string[] => {
    // `option.text` is the key we use to determine if an option should be preselected
    const preselected = ProductModifierManager.PreSelectedModifierTitles;

    /**
     * Look in the given modifier to see if it contains an option from our `preselected` array,
     * if so, return its id so we can include it in the modifiers `selectedOptions`.
     */
    const optionIds = modifier.options.filter((opt) => preselected.includes(opt.text)).map((opt) => opt.id);
    return optionIds;
  };

  public static readonly setSelections = (
    modifiers: Modifier[],
    isSelectable: boolean,
    parentOption?: ModifierOption
  ): ProductModifier[] => {
    const selectedModifierOptions: ProductModifier[] = [];
    modifiers.forEach((modifier) => {
      if (!isOptionModifier(modifier)) return;
      const selectedOptions = modifier.defaultOptions ? modifier.defaultOptions : [];
      const preSelectedOptions = ProductModifierManager.getPreSelectedOptions(modifier);

      const productModifier: ProductModifier = {
        ...modifier,
        enabled: isSelectable,
        type: preSelectedOptions.length > 0 ? ModifierType.PreSelect : modifier.type,
        selectedOptions: selectedOptions.concat(preSelectedOptions),
        parentOptionId: parentOption?.id,
      };

      selectedModifierOptions.push(productModifier);

      modifier.options.forEach((option) => {
        if (!option.modifiers) return;
        const canSelect: boolean = productModifier.selectedOptions.includes(option.id) && productModifier.enabled;
        selectedModifierOptions.push(...ProductModifierManager.setSelections(option.modifiers, canSelect, option));
      });
    });

    return selectedModifierOptions;
  };

  /** Recursively enabled child modifiers that should be enabled. */
  private static readonly enableChildModifiers = (
    productModifiers: ProductModifier[],
    changedOptionIds: ModifierOptionId[]
  ): ProductModifier[] => {
    if (!changedOptionIds) return productModifiers;

    const changedProductModifiers = productModifiers.filter(
      (pm) => pm.parentOptionId && changedOptionIds.includes(pm.parentOptionId)
    );

    if (!changedProductModifiers) return productModifiers;

    const selectedOptionIds = ProductModifierManager.flattenSelectedOptionIds(productModifiers);

    changedProductModifiers.forEach((pm) => {
      if (!pm.parentOptionId) return;
      pm.enabled = selectedOptionIds.includes(pm.parentOptionId);
      pm.options.forEach((option) => {
        if (!option.modifiers) return;
        ProductModifierManager.enableChildModifiers(productModifiers, [option.id]);
      });
    });
    return productModifiers;
  };

  /** Recursively sets options based on allOptions */
  public static readonly setSelectionsForCart = (
    modifiers: Modifier[],
    allOptions: ModifierOptionId[],
    isSelectable: boolean,
    parentOption?: ModifierOption
  ): ProductModifier[] => {
    const selectedModifierOptions: ProductModifier[] = [];
    modifiers.forEach((modifier) => {
      if (!isOptionModifier(modifier)) return;
      const selectedOptions = modifier.options.filter((o) => allOptions.includes(o.id)).map((o) => o.id);
      const preSelectedOptions = ProductModifierManager.getPreSelectedOptions(modifier);

      const productModifier: ProductModifier = {
        ...modifier,
        enabled: isSelectable,
        type: preSelectedOptions.length > 0 ? ModifierType.PreSelect : modifier.type,
        /**
         * Merge the selectedOptions for the cart product with the preSelectedOptions, ensuring nested modifiers for unselected options are populated.
         * Using [...new Set(arrayOfItems)] guarantees no duplicate entries: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#remove_duplicate_elements_from_an_array
         * @see setSelections for a similar approach of array merging via `concat`.
         */
        selectedOptions: [...new Set(selectedOptions.concat(preSelectedOptions))],
        parentOptionId: parentOption?.id,
      };

      selectedModifierOptions.push(productModifier);

      modifier.options.forEach((option) => {
        if (!option.modifiers) return;
        const canSelect: boolean = productModifier.selectedOptions.includes(option.id) && productModifier.enabled;
        selectedModifierOptions.push(
          ...ProductModifierManager.setSelectionsForCart(option.modifiers, allOptions, canSelect, option)
        );
      });
    });

    return selectedModifierOptions;
  };
}
